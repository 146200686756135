.default-content {
    background: #ededed;
    padding: 10px 10px 50px 10px;
    margin-top: 120px;
    min-height: 45vh;
    /* Small devices (tablets, 768px and up) */
    @media (min-width: $screen-sm-min) {
        padding: 65px 40px 0 40px;
    }

    @media (min-width: $screen-md-min) {
        margin-top: 80px;
    }
}

.space-top {
    padding-bottom: 20px;
}

.push-down-xs {
	margin-bottom: 20px;

	/* Small devices (tablets, 768px and up) */
	@media (min-width: $screen-sm-min) {
		margin-bottom: 0;
	}
}

.vcenter {
	display: flex;
	align-items: center;
}

.back-link {
	display: inline-block;
	margin-right: 45px;
	font-size: 15px;
	float: left;
	position: relative;
	top: 5px;

	span {
		display: inline-block;
		margin-right: 10px;
	}
}

hr {
	height: 1px;
	border: 0;
	background: #979797;
	margin: 50px auto;

	&.light {
		background: #CFCFCF;
		margin: 30px auto;
	}
}

.main {
    background: #fafafa url($background-img) top center no-repeat;
    background-size: 100%;
    overflow: hidden;
    //background-attachment: fixed;
}

.icon {
	display: inline-block;
	width: 25px;
	height: 25px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	&.icon-delete {
		background-image: url('/images/icon_x.png');
		
	}

	&.icon-tick {
		width: 15px;
		height: 15px;
		background-image: url('/images/icon_tick.png');
	}
}

.row.small-gutter {
	margin-left: -1px;
	margin-right: -1px;
	
	*[class^="col"] {
		padding-left: 1px;
		padding-right: 1px;
	}
} 
