$brand-primary: #363636;
$custom-input-bg: #FFFFFF;

$top-bg: #363636;
$service-footer-bg: #D8D8D8;
$footer-bg: #363636;
$filter-panel-color: #363636;
$logo-img: "/images/denbedstegave/DBG_logo_white.png";
$background-img: "/images/denbedstegave/1920x900px_DBG_BG_V3.jpg";

.hero {
    background: #fafafa url("/images/denbedstegave/BG_top_banner_v1.jpg") left center no-repeat;
    background-size: cover;
    height: 215px;
    margin-top: 120px;

    @media (min-width: $screen-md-min) {
        margin-top: 80px;
    }
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.redeem-card-img {
    background-image: url("/images/denbedstegave/BG_Redeem_Card_for_web.jpeg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.top-logo {
    background-image: url($logo-img);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.footer-logo {
    background-image: url($logo-img);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;

    @media (min-width: 992px) {
        float: left;
        margin-left: 15px;
    }
}

.filter-panel .container {
    @media (min-width: 720px) {
        margin-left: 0;
    }
}

.cc-btn {
    background-color: $brand-primary !important;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.main#custom-theme { /*using a custom theme ID makes it possible to get rid of important usage.*/
    .price-column {
        background: none;
    }

    .price-column .items li .price-total {
        font-size: 16px;
    }

    .big-logo {
        width: 94px;
        float: left;
        margin-top: 7px;

        @media (min-width: $screen-sm-min) {
            margin-top: 5px;
        }
    }

    .container {
        padding-left: 0px;
        padding-right: 0px;
    }

    .main {
        background: #fafafa url($background-img) top center no-repeat;
        color: #262626;
    }


    .hero {

        .title {
            margin-top: 0px;
            margin-left: 20px;

            span {
                position: relative;
                z-index: 2;
                width: 100%;
                text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.6);
                color: #fff;
                font-size: 36px;
                line-height: 44px;
                font-weight: bold;
                /* Small devices (tablets, 768px and up) */
                /* Large devices (large desktops, 1200px and up) */
            }

            span .small {
                font-size: 28px;
            }
        }
    }

    .top .logo-wrapper {
        width: 25%;
        background-size: cover;

        @media (min-width: $screen-md-min) {
            width: 95px;
        }

        img {
            height: 100%;
        }
    }

    .btn-primary {
        border-radius: 5px;
    }

    .basket-container {
        .basket-icon {
            border-radius: 5px;
            min-width: 60px;

            @media (min-width: $screen-sm-min) {
                border-radius: 5px;
                color: #6C6C6C;
            }
        }
    }

    .top {
        height: 70px;
        position: fixed;

        @media (min-width: $screen-md-min) {
            top: 0;
        }
    }

    .row.small-gutter {
        *[class^="col"] {
            padding-left: 10px;
            //padding-right: 5px;
        }
    }

    .gift-cards ul li {
        margin-bottom: 10px;
    }

    .service-footer {
        color: black;
        font-size: 14px;
    }

    .service-footer ul li a {
        color: black;
        font-size: 14px;
    }

    .filter-panel button {
        font-weight: lighter;
    }

    .top .basket-container .basket-icon:after {
        @media (min-width: $screen-sm-min) {
            background-image: url(/images/bestegaven/kurv.png);
            /*height: 44px;
            width: 57px;*/
        }
    }

    footer {
        color: #FFFFFF;
        font-size: 14px;
    }

    footer a {
        color: #FFFFFF;
        font-size: 14px;
    }

    footer p {
        margin: 0;
    }
    /*.top .basket-container a {


        @media (min-width: $screen-sm-min) {
            height: 49px;
            width: 76px;
            padding-top: 5%;
            font-size: 18px;
        }

        color: #6C6C6C;
    }*/
    .top .btn-redeem {
        background: white;
        color: #6C6C6C;
        border-color: white;
        margin-left: 5px;

        @media (min-width: $screen-sm-min) {
            margin-left: 0px;
            /*height: 44px;
            width: 57px;*/
        }
        /*height: 36px;*/
        //padding-top: 12px;
        /*@media (min-width: $screen-sm-min) {
            padding-top: 12px;
            margin-top: 10px;
            font-size: 18px;
            height: 49px;
        }*/
    }
    /*
    .top .basket-container {
        padding-top: 12px;
    }

    .top .menu li a {
        @media (min-width: $screen-sm-min) {
            font-size: 18px;
        }
    }

    .top .menu li {
        @media (min-width: $screen-sm-min) {
            height: 17px;
        }
    }

    .top .nav-basket {

        @media (min-width: $screen-sm-min) {
            top: 20px;
            position: relative;
        }
    }*/
    .service-footer ul {
        margin: 10px 0 0 0;
        list-style-type: none;
    }

    .service-footer .col-xs-12 {
        margin-bottom: 20px;

        @media (min-width: 992px) {
            margin-bottom: 0px;
        }
    }
    /*.top .basket-container .basket-dropdown .btn {
        width: 100%;
        padding: 12px;
        color: #fff;
    }

    .top .basket-container .basket-icon:before {
        height: 49px;
    }*/
    .service-footer strong {
        font-weight: bold;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10 & IE11 CSS styles go here */
        .ie1011 {
            margin-left: 0px;
        }
    }

    .top .menu {
        background: #999;

        @media (min-width: 992px) {
            background: transparent;
        }
    }

    @media (max-width: 991px) and (min-width: 768px) {
        /*.top .menu {
            top: 100px;
            width: 91rem;
            left: -33rem;
        }*/
        .top .nav-basket {
            justify-content: unset;
        }
    }

    .select-fancy {
        color: #6C6C6C;
        height: 55px;
        line-height: 55px;

        :hover {
            color: #6C6C6C;
        }

        :focus {
            color: #6C6C6C;
        }
    }

    .quantity-selector .add {
        background: #363636;
        border-radius: 0px 5px 5px 0px;
        width: 44px;
        height: 40px;
    }

    .quantity-selector .subtract {
        background: #363636;
        border-radius: 5px 0px 0px 5px;
        width: 44px;
        height: 40px;
    }

    .quantity-selector .quantity {
        width: 64px;
        height: 40px;
        margin: 0;
        border-radius: 0;
        border: 0px;
    }

    .put-btn {
        height: 40px;
    }

    .select-fancy select {
        height: 55px;
    }

    .basket-page .added-items li .price {
        font-size: 18px;
    }

    .basket-page .added-items li {
        padding: 0;
    }

    .basket-page .added-items li .img-responsive {
        margin-bottom: 0px;
    }

    basket-page .added-items li {
        .quantity {
            background: #F0F0F0;
            color: #6C6C6C;
        }
    }

    .price-column form input[type="text"] {
        border-radius: 5px;
        background-color: white;
        box-shadow: none;
        width: 75%;
        float: right;
    }

    .price-column form button {
        border-radius: 5px;
        background: none;
        border: #363636 solid 1px;
        color: #363636;
        font-weight: bolder;
        width: 75%;
        float: right;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .price-column form .btn {
        width: 75%;
        float: right;
    }

    .select-fancy:after {
        color: #6C6C6C;
    }

    .gift-cards ul li a.gift-card .text {
        background: rgba(240, 143, 150, 0.8);
    }

    .gift-cards {
        background: none;
        padding-top: 7px;
    }

    .btn-redeem {
        float: left;
        margin-top: 16px;
        padding: 5px 15px;
        display: inline-block;
        line-height: 25px;
        text-align: center;
    }

    .basket-icon {
        padding: 5px 15px;
        display: inline-block;
        line-height: 27px;
        text-align: center;
    }

    footer #cardlogos {
        width: 100%;
        left: auto;
        text-align: center;
        margin-left: 0;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    /*.filter-panel button {
        height: 30px;
        background: none;
        color: white;
        line-height: 0;
        font-size: 14px;
        margin-top: 5px;
        border-radius: 4px;
    }*/
    /*.filter-panel button.selected {
        color: #363636;
        background-color: #FFFFFF;
    }*/
    /*.filter-panel .wrapper {
        padding-left: 5px;
    }*/

    .filter-panel {
        background: none;

        .container {
            background-color: #363636;
        }

        .content {
            height: 40px;
        }

        .wrapper {
            padding-left: 5px;
        }

        .nav-pills > li {
            & > a {
                font-weight: lighter !important;
                height: 30px;
                background: none;
                color: white !important;
                line-height: 15px;
                font-size: 14px;
                margin-top: 5px;
                border-radius: 4px;
                padding: 7px;
                border: 0;
                outline: 0;
            }

            &.active {
                & > a {
                    color: #363636 !important;
                    background-color: #FFFFFF;
                    padding: 7px 15px !important;
                }
            }
        }
    }

    .gift-cards {
        .content {
            ul.row.small-gutter:first-child {
                margin-left: -10px;
            }
        }
    }
}

#overlay {
    display: table;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 10000;
}

.white_content {
    position: relative;
    background-color: white;
    z-index: 10001;
    margin-left: auto;
    margin-right: auto;
    width: 300px; /*whatever width you want*/
    @media (min-width: 992px) {
        width: 400px; /*whatever width you want*/
    }

    padding: 20px;
}

#lightbox {
    display: table-cell;
    vertical-align: middle;
}

.row-eq-height {
    @media (min-width: 992px) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
}
