body {
    font-family: 'Interstate', 'Open Sans', Arial, Verdana;
}

a:hover {
    text-decoration: none;
}

h1 {
    color: #fff;
    font-size: 38px;
    line-height: 44px;
    font-weight: bold;
    /* Small devices (tablets, 768px and up) */
    @media (min-width: $screen-sm-min) {
        font-size: 38px;
        line-height: 40px;
    }
    /* Medium devices (desktops, 992px and up) */
    @media (min-width: $screen-md-min) {
        font-size: 60px;
        line-height: 65px;
    }
    /* Large devices (large desktops, 1200px and up) */
    @media (min-width: $screen-lg-min) {
    }
}

h2 {
    font-size: 21px;
    color: #474C52;
    line-height: 23px;
    margin: 0 0 20px 0;
    font-weight: normal;
    /* Small devices (tablets, 768px and up) */
    @media (min-width: $screen-sm-min) {
        font-size: 25px;
        line-height: 31px;
    }
}

h3 {
    color: #fff;
    font-size: 25px;
    line-height: 34px;
    font-weight: normal;
    /* Small devices (tablets, 768px and up) */
    @media (min-width: $screen-sm-min) {
        font-size: 30px;
    }
    /* Medium devices (desktops, 992px and up) */
    @media (min-width: $screen-md-min) {
    }
    /* Large devices (large desktops, 1200px and up) */
    @media (min-width: $screen-lg-min) {
    }
}

strong {
    font-weight: 500;
}
