﻿
.PensioEmbeddedPaymentWindow {
    height: 550px !important;
    width: 100%;
}

.row-payment {
    margin-bottom: 8px;
}

.AltaPaySubmitButton {
    background-color: green !important;
}

.error {
    display: flex !important;
    align-items: center;
    align-items: flex-start;
    max-width: 400px;
    width: 400px;
}

.ListRounded {
    display: flex !important;
    align-items: center;
    background-color: inherit;
    color: white;
    max-width: 400px;
    align-items: center;
    border-radius: 1rem !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    width: 100%;
    justify-content: space-between;

    &:hover {
        color: black;
        background-color: bisque;
    }
}

.PensioEmbeddedPaymentWindowBackground {
    background-color: inherit !important;
}

.payment-method-logo {
    margin-right: 10px;
    width: 30px !important;
    height: 30px !important;
}

#altapay-checkout-content {
    display: flex;
    flex-direction: column !important;

    p {
        text-align: center;
    }
}

#altapay-error-content {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
}

#ChoosenPaymentAltaPay {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.selected-altapayment .ListRounded {
    color: #212529;
    background-color: #e9ecef;
}

#altaPay-checkout-paymentMethods {
    display: flex !important;
    flex-direction: column !important;
}

.d-none {
    display: none;
}

.altapay-payment-method-image-wrapper {
    display: flex;
}

#altaPayErrorMessage {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 2rem;
}