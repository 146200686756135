@import "morebox_partials/variables";

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "DenBedsteGave_partials/_theme_settings.scss";

@import "DenBedsteGave_partials/typography";
@import "morebox_partials/buttons";
@import "morebox_partials/forms";
@import "DenBedsteGave_partials/generic_elements";
@import "morebox_partials/lightbox";


@import "DenBedsteGave_partials/top";
@import "morebox_partials/hero";
@import "morebox_partials/filter_panel";
@import "morebox_partials/gift_cards";
@import "morebox_partials/product_view";
@import "morebox_partials/basket_page";
@import "morebox_partials/price_column";
@import "morebox_partials/checkout";
@import "morebox_partials/confirmation";
@import "morebox_partials/faq";
@import "morebox_partials/redeem";
@import "morebox_partials/how_to_footer";
@import "morebox_partials/_footer.scss";
@import "morebox_partials/_loading_andimation";
@import "Default2/_AltaPay.scss";

.d-none {
    display: none !important;
}

.ListRounded {
    color: #fff !important;
    margin-bottom: 1rem !important;
}

.selected-altapayment .ListRounded {
    color: #212529 !important;
    background-color: #e9ecef;
}